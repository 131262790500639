import React from 'react';
import { getMediaByRelation } from '@alterpage/gatsby-plugin-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { BaseImage, Image } from '@alterpage/gatsby-plugin-image';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';

import {
    container,
    formBox,
    content as contentClass,
    sectionBox,
    formBoxHeader,
    formBoxTitle,
    formBoxRegister,
    formBoxRegisterLink,
    contentTitle,
    contentHeader,
    videoBox,
    videoRatio,
    video,
} from './sign-in.module.scss';
import { ISection } from '../../models/section.model';
import { ILogo } from '../../models/logo.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { relations } from '../../config/relations';
import useTranslations from '../../hooks/use-translations';
import { getUrlSearch } from '../../utils/get-url-search';
import { usePagePathname } from '../../hooks/use-page-pathname';

import Section from '../hoc/section';
import LoginForm from '../molecules/login-form';
import Markdown from '../hoc/markdown';

interface ISignInQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale' | 'type'>>;
    candidateImage: ImageDataLike | null;
    clientImage: ImageDataLike | null;
}

export type TSingInType = 'candidate' | 'client';

export interface ISignInSection extends ISection {
    items: {
        logo: ILogo | null;
    };
}

export interface ISignInProps {
    type: TSingInType;
    className?: string;
    TitleTag?: React.ElementType;
    section: ISignInSection;
}

export default function SignIn({
    className,
    section,
    TitleTag = 'h2',
    type = 'candidate',
}: ISignInProps) {
    const { items, css, style, sectionId, content } = section;
    const logo = items?.logo;
    const t = useTranslations('SignIn');
    const { allPage, clientImage, candidateImage } = useStaticQuery<ISignInQueryResult>(query);
    const search = getUrlSearch();

    const registerPageType = type === 'client' ? 'register-client' : 'register-candidate';
    const registerPage = usePagePathname(allPage, registerPageType);
    const passwordRecoveryPage = usePagePathname(allPage, 'password-recovery');

    const image = type === 'candidate' ? candidateImage : clientImage;

    const introductionVideo = getMediaByRelation({
        media: content.media,
        relation: relations.mainVideo,
        useFallback: false,
    });

    return (
        <Section
            className={`${className} ${sectionBox}`}
            TitleTag={TitleTag}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            <div className={container}>
                <div className={formBox}>
                    <div className={formBoxHeader}>
                        <TitleTag className={formBoxTitle}>{t[type].formTitle}</TitleTag>
                        <p className={formBoxRegister}>
                            {t[type].registerText}{' '}
                            <Link
                                className={formBoxRegisterLink}
                                to={`${registerPage}${type === 'candidate' ? search : ''}`}
                            >
                                {t[type].registerLink}
                            </Link>
                        </p>
                    </div>
                    <div>
                        <LoginForm passwordRecoveryLink={passwordRecoveryPage} userType={type} />
                    </div>
                </div>
                <div className={contentClass}>
                    <div className={contentHeader}>
                        {logo && <Image media={logo.media} />}
                        <Markdown className={contentTitle}>{t[type].contentTitle}</Markdown>
                    </div>
                    {type === 'candidate' ? (
                        <div className={videoBox}>
                            <div className={videoRatio}>
                                {introductionVideo?.url && (
                                    <video
                                        autoPlay={true}
                                        className={video}
                                        src={introductionVideo.url}
                                        muted={true}
                                        playsInline={true}
                                        loop={true}
                                    />
                                )}
                            </div>
                        </div>
                    ) : (
                        <BaseImage image={getImage(image)} />
                    )}
                </div>
            </div>
        </Section>
    );
}

const query = graphql`
    query {
        allPage(
            filter: { type: { in: ["register-client", "register-candidate", "password-recovery"] } }
        ) {
            edges {
                node {
                    locale
                    type
                    pathname
                }
            }
        }
        candidateImage: file(relativePath: { eq: "sign-in-candidate.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: NONE, quality: 95)
            }
        }
        clientImage: file(relativePath: { eq: "sign-in-client.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: NONE, quality: 95)
            }
        }
    }
`;
