import React from 'react';
import { Link } from 'gatsby';

import { container, form, recoveryLink, formGrid } from './login-form.module.scss';
import { loginForm } from '../../formik/login.form';
import useTranslations from '../../hooks/use-translations';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import { useCandidate } from '../../hooks/use-candidate';
import { useClient } from '../../hooks/use-client';

import FormGenerator from './form-generator';

export interface ILoginFormProps {
    className?: string;
    passwordRecoveryLink?: string;
    userType: 'client' | 'candidate';
}

export default function LoginForm({
    className = '',
    passwordRecoveryLink,
    userType,
}: ILoginFormProps) {
    const t = useTranslations('LoginForm');
    const candidate = useCandidate();
    const client = useClient();
    const user = userType === 'client' ? client : candidate;
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(user.login.errors);

    return (
        <div className={[container, className].join(' ')}>
            <FormGenerator
                formRef={formikRef}
                className={form}
                formClassName={formGrid}
                formikProps={{ onSubmit: (values) => user.login.fetch(values) }}
                name="LoginForm"
                fields={loginForm}
                errorMessage={globalErrorMessage}
                submitLoading={user.login.isLoading}
            >
                {passwordRecoveryLink && (
                    <Link to={passwordRecoveryLink} className={recoveryLink}>
                        {t.passwordRecovery}
                    </Link>
                )}
            </FormGenerator>
        </div>
    );
}
