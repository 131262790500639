// extracted by mini-css-extract-plugin
export var backgroundAnimation = "sign-in-module--backgroundAnimation--8688c";
export var container = "sign-in-module--container--dc18d";
export var content = "sign-in-module--content--81e19";
export var contentHeader = "sign-in-module--content-header--71736";
export var contentTitle = "sign-in-module--content-title--19f9a";
export var formBox = "sign-in-module--form-box--2191f";
export var formBoxHeader = "sign-in-module--form-box-header--de49e";
export var formBoxRegister = "sign-in-module--form-box-register--25e80";
export var formBoxRegisterLink = "sign-in-module--form-box-register-link--1788d";
export var formBoxTitle = "sign-in-module--form-box-title--1a975";
export var sectionBox = "sign-in-module--section-box--e18c8";
export var video = "sign-in-module--video--e9049";
export var videoBox = "sign-in-module--video-box--02d35";
export var videoRatio = "sign-in-module--video-ratio--dd14c";